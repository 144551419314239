import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { ModalController, Platform, AlertController } from '@ionic/angular';
import { ModalPage } from '../modal/modal.page';
import { RestService } from '../services/rest.service';
import { BananaPage } from '../modules/banana/banana.page';
import { NewStatisticsService } from '../services/newstatistics.service';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import QRCode from 'qrcode';

//https://github.com/apache/cordova-plugin-screen-orientation

@Pipe({ name: 'keys', pure: false })
export class KeysPipe implements PipeTransform {
  transform(value: any, args: any[] = null): any {
    return Object.keys(value)//.map(key => value[key]);
  }
}
@Component({
  selector: 'app-home-new',
  templateUrl: './home-new.page.html',
  styleUrls: ['./home-new.page.scss'],
})
export class HomeNewPage implements OnInit {
  language: string = this.translateService.currentLang;
  objectKeys = Object.keys;
  stringToNumber = Number.parseInt;
  mathRound = Math.round;
  JSON = JSON;
  sizeUpper: string;
  demo:any;
  blanes = false;
  gridVertical: any;
  gridHorizontal: any;
  daysWeekShortening: string[];
  daysWeek: string[];
  clockHour: any;
  bnna:any;
  clockMinute: any;
  clockDay: any;
  platano = 0;
  easter = false;
  clockMonth: any;
  clockYear: number;
  eventFire: boolean = false;
  interval: any;
  videosUrl: any;
  videoSlider: any;
  event1: any;
  event2: any;
  /* intervalEvents: NodeJS.Timer;
  intervalClock: NodeJS.Timer; */
  intervalEvents: any;
  intervalClock: any;

  //Añadido Banner Granada
  mac_granada: any = [
    '12:12:12:12:12:12',
    '01:00:01:00:01:00',
    '00:a5:4c:68:30:ae',
    '00:a2:e5:68:93:a5',
    'a4:6b:b6:a9:f3:9e',
    '00:a1:ec:68:b2:e4',
    '00:a2:e5:68:92:e7',
    '00:a1:ec:68:b3:ae',
    'a4:6b:b6:a9:ef:75',
    '1c:69:7a:00:ab:84'
  ];



  GUI: any;
  bannerBackgroundColor: any;
  qr: any;
  color_corp: any;
  heigth_slider: any;
  activeLangs: any;

  constructor(public rest: RestService,public statisticals: NewStatisticsService, public screenOrientation: ScreenOrientation, 
    public platform: Platform, private router: Router, private translateService: TranslateService,  
    private modalCtrl: ModalController, public alertController: AlertController) {
    // this.rest.imageLang = "https://api.iurban.es/public/uploads/language/image_20200701054726__60-espana-sin-escudo_400px.jpg";

    //Añadido Multi-Idioma selector desde CMS
    this.activeLangs = rest.getActiveLangs();
    console.log("LANGS HOME: ", this.activeLangs);


    this.color_corp = this.rest.colorCorp;

    //Subida Alto Slider Video (BLANES PARA VER SUBTITULOS)
    this.heigth_slider = '28%';
    if (this.rest.mac === "blanesnew"){
      this.heigth_slider = '37%';
    }

    // GUARDAR EN LOCALSTORAGE PARAMETROS PARA LOGIN AUTOMATICO AL REFRESCO DE PANTALLA
    if (this.rest.loaded == true && this.rest.mac == 'rincon'){
      this.router.navigate(["/iframe"], {queryParams: {link: 'https://www.turismoenrincon.es/'}});
    }

    // if (this.rest.loaded == true && (this.rest.mac == 'Cantur' || this.rest.mac == '1c:69:7a:ac:3c:f0')){
    //   this.router.navigate(["/iframe"], {queryParams: {link: 'https://www.cantur.net/santander/Tourist_php/piCarrusel.php'}});
    // }

    //Añadido al Refrescar APP se redirija a la HOME
    if (Object.keys(this.rest.response).length === 0){    
        this.router.navigateByUrl('/');
        this.rest.cachedURL = '/home-new';
    }

    this.rest.onChangeLanguageNew(this.rest.usedLang ? this.rest.usedLang : 1, this.rest.codeUsedLang ? this.rest.codeUsedLang : 'es' , -1);

    console.log('kevinNewHome');
    this.sizeUpper = this.rest.size.charAt(0).toUpperCase() + this.rest.size.slice(1);
    this.gridVertical = this.rest.response.items[this.sizeUpper + 'Vertical'];
    
    //Comprobar que vengan estilos del JSON para que no pete APP a su creacion desde CMS -- Temporal hasta arreglo desde CMS
    const toolBarStyle = this.rest.response.items[this.sizeUpper + 'Vertical'].subtoolbar;
    if (toolBarStyle.show && toolBarStyle.style === undefined){
        const toolBarStyleJson = {
          "fontSize": 0.8,
          "fontColor": "#FFFFFF",
          "fontFamily": "Helvetica",
          "backgroundColor": "#F5F5F5",
          "backgroundColorElement": "#3a99d7",
          "shawdow": "0px 0px 0px rgba(0,0,0,1) transparent",
          "fontBold": "normal",
          "fontItalic": "normal",
          "fontUnderline": "none"
        }
        toolBarStyle.style = toolBarStyleJson;      
    }

      //Comprobar que vengan estilos del JSON para que no pete APP a su creacion desde CMS -- Temporal hasta arreglo desde CMS
      const bannerStyle = this.rest.response.items[this.sizeUpper + 'Vertical'].banner;
      if (bannerStyle.show && bannerStyle.style === undefined){
          const bannerStyleJson = {
            "fontSize": 0.8,
            "fontColor": "#FFFFFF",
            "fontFamily": "Helvetica",
            "backgroundColor": "#F5F5F5",
            "backgroundColorElement": "#3a99d7",
            "backgroundColorExpand": "#025c81",
            "shawdow": "0px 0px 0px rgba(0,0,0,1) transparent",
            "fontBold": "normal",
            "fontItalic": "normal",
            "fontUnderline": "none"
          }
          bannerStyle.style = bannerStyleJson;      
      }

    this.gridHorizontal = this.rest.response.items[this.sizeUpper + 'Horizontal'];

    if(this.gridVertical.banner.show == 'true'){
    this.bannerBackgroundColor = this.gridVertical.banner.style.backgroundColor;}

    this.daysWeekShortening = ['Dom','Lun','Mar','Mie','Jue','Vie','Sab'];
    this.daysWeek = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
    if(this.gridVertical.slider == true){
    if(this.gridVertical.slider.video){
      this.videosUrl = Object.entries(this.gridVertical.slider.videosUrl).reduce((_videos, [_key, {url, time}]: any) => {
        return [
          ..._videos,
          {
            url: url,
            time: time
          }
        ]
      }, []);
    }}
    this.event1 = {
      image: '',
      title: '',
      content: ''
    };
    this.event2 = {
      image: '',
      title: '',
      content: ''
    }
  }

  ngOnInit() {
    this.rest.pointinterestid = null;
    let clientId = this.rest.response.client;
    this.statisticals.clientId = clientId;
    if(!this.statisticals.counter){
      this.statisticals.startNewSession();
      this.statisticals.mac = this.rest.mac;
    }
    if(this.rest.blanes == true){
      this.blanes = true;
    }
  }

  ngAfterViewInit(){
    //Cambio estilo botones IONIC
    // const ionButtons = window.document.querySelectorAll('ion-button');
    // ionButtons.forEach(button => {
    //   button.style.cssText = "--background: " + this.color_corp;
    // });
    // const ionFabButtons = window.document.querySelectorAll('ion-fab-button');
    // ionFabButtons.forEach(button => {
    //   button.style.cssText = "--background: " + this.color_corp;
    //   button.style.width = '40px';
    //   button.style.height = '40px';
    // });
        
  }
  
  ionViewDidEnter(){
    console.log("ANT-RES: ", this.rest);
    if (this.platform.isPortrait()) {
      console.log("i am portrait!!!")
    }
    if (this.platform.isLandscape()) {
    }
    this.statisticals.device = 'desktop';
    if (this.platform.is('mobile')) {
      // This will only print when on iOS
      console.log('I am an iOS device!');
        this.statisticals.device = 'mobile';
    }
    if(this.rest.demo == true){
      this.demo = true;
    }
    if(this.rest.size == 'mobile'){
     let homeButton = document.getElementById('homeButton') 
     homeButton.classList.add('toolbar-element-home-mobile');
     homeButton.classList.remove('toolbar-element-home');
    }

    this.GUI = {
      bannerMonitor: window.document.getElementById('bannerMonitor')
    }
    this.clockToolbar();
    console.log(Object.entries(this.gridVertical.slider.videosUrl).length);
    if(this.gridVertical.slider.video && Object.entries(this.gridVertical.slider.videosUrl).length > 1){
      this.videoSlider = window.document.getElementById('slider');
      this.nextVideo();
    }
    const itemEvent =  window.document.getElementById('eventDinamyc');
    console.log(itemEvent);
    if(itemEvent){
      const itemId = JSON.parse(itemEvent.dataset.itemkey);
      console.log(itemId);
      const eventDatas = itemId.reduce((_allEvents, _eventId) => {
        const allEvents = this.rest.response.datas['event' + _eventId].events.reduce((_eventDatas, _event) => {
          return [..._eventDatas, {
            image: `no-repeat center/contain url(${_event.foto})`,
            title: _event.title,
            content: _event.description
          }]
        }, []);
        return [..._allEvents, ...allEvents];
      }, []);
      console.log(eventDatas);
      this.dinamycEvent(itemEvent, eventDatas);
    }
    
  }
  ionViewWillLeave(){
    window.clearTimeout(this.intervalEvents);
    window.clearTimeout(this.intervalClock);
    window.clearTimeout(this.interval);
  }
  dinamycEvent(itemEvent, eventDatas){
    let eventNumber = parseInt(itemEvent.dataset.eventnumber);
    if(eventNumber > eventDatas.length - 2){
      eventNumber = 0;
    }
    this.event1 = {
      image: eventDatas[eventNumber].image,
      title: eventDatas[eventNumber].title,
      content: eventDatas[eventNumber].content
    };
    this.event2 = {
      image: eventDatas[eventNumber + 1].image,
      title: eventDatas[eventNumber + 1].title,
      content: eventDatas[eventNumber + 1].content
    };
    itemEvent.dataset.eventnumber = eventNumber + 2;
    this.intervalEvents = setTimeout(() => {
      this.dinamycEvent(itemEvent, eventDatas);
    }, 15000);
  }

  async nextVideo(){
    if(!this.eventFire){
      const firstVideo: any = window.document.querySelector(`ion-slide[data-swiper-slide-index='0'] > video`);
      console.log(firstVideo);
      if(firstVideo !== null){
        firstVideo.play();
        this.eventFire = true;
      }else{
        this.interval = setTimeout(() => {
          this.nextVideo();
        }, 1000);
        return '';
      }
    }
    const slidesIndex = await this.videoSlider.getActiveIndex();
    const slidesIndexElement = slidesIndex > this.videosUrl.length 
                              ? 0 
                              : slidesIndex - 1;
    const slidesTime = typeof this.videosUrl[slidesIndexElement] !== undefined
                      ? this.videosUrl[slidesIndexElement].time * 1000
                      : 7777;
    console.log(slidesIndexElement);
    /* const videoCurrent: any = window.document.querySelector(`ion-slide[data-swiper-slide-index='${slidesIndexElement}'] > video`);
    videoCurrent.play(); */
    const videosSlider: any = window.document.querySelectorAll(`ion-slide[data-swiper-slide-index='${slidesIndexElement}'] > video`);
    [...videosSlider].map(_video => {
      _video.muted = true;
      _video.play();
      return '';
    });
    this.interval = setTimeout(() => {
      this.videoSlider.slideNext(1000);
      
      this.nextVideo();
    }, slidesTime);
  }
  gradientToolbar(orientation: string){
    return `linear-gradient(${this['grid' + orientation].toolbar.color_direction}, ${this['grid' + orientation].toolbar.color_start} 0%, ${this['grid' + orientation].toolbar.color_end} 100%)`;
  }
  toolbarElementClass(type: string){
    return 'toolbar-element-' + type;
  }
  appHeightStyle(orientation: string){
    const sizeApplication = {
      mobile: {
        normal: {
          Vertical: {
            appHeight: '89%',
            appMarginTop: '0%'
          },
          Horizontal: {
            appHeight: '89%',
            appMarginTop: '0%'
          }
        },
        subtoolbar: {
          Vertical: {
            appHeight: '79%',
            appMarginTop: '30%'
          },
          Horizontal: {
            appHeight: '89%',
            appMarginTop: '8%'
          }
        },
        banner: {
          Vertical: {
            appHeight: '75%',
            appMarginTop: '0%'
          },
          Horizontal: {
            appHeight: '75%',
            appMarginTop: '0%'
          },
        },
        subtoolbarbanner: {
          Vertical: {
            appHeight: '65%',
            appMarginTop: '30%'
          },
          Horizontal: {
            appHeight: '75%',
            appMarginTop: '8%'
          }
        }
      },
      tablet: {
        normal: {
          Vertical: {
            appHeight: '89%',
            appMarginTop: '0%'
          },
          Horizontal: {
            appHeight: '89%',
            appMarginTop: '0%'
          }
        },
        subtoolbar: {
          Vertical: {
            appHeight: '79%',
            appMarginTop: '16%'
          },
          Horizontal: {
            appHeight: '89%',
            appMarginTop: '8%'
          }
        },
        banner: {
          Vertical: {
            appHeight: '75%',
            appMarginTop: '25%'
          },
          Horizontal: {
            appHeight: '75%',
            appMarginTop: '0%'
          }
        },
        subtoolbarbanner: {
          Vertical: {
            appHeight: '65%',
            appMarginTop: '25%'
          },
          Horizontal: {
            appHeight: '75%',
            appMarginTop: '8%'
          }
        }
      },
      monitor: {
        normal: {
          Vertical: {
            appHeight: '100%',
            appMarginTop: '0%'
          },
          Horizontal: {
            appHeight: '100%',
            appMarginTop: '0%'
          }
        },
        subtoolbar: {
          Vertical: {
            appHeight: '84%',
            appMarginTop: '0%'
          },
          Horizontal: {
            appHeight: '92%',
            appMarginTop: '0%'
          }
        },
        banner: {
          Vertical: {
            appHeight: '70%',
            appMarginTop: '0%'
          },
          Horizontal: {
            appHeight: '90%',
            appMarginTop: '0%'
          }
        },
        video: {
          Vertical: {
            appHeight: '68%',
            appMarginTop: '0%'
          },
          Horizontal: {
            appHeight: '89%',
            appMarginTop: '0%'
          }
        },
        subtoolbarbanner: {
          Vertical: {
            appHeight: '80%',
            appMarginTop: '0%'
          },
          Horizontal: {
            appHeight: '80%',
            appMarginTop: '0%'
          }
        },
        subtoolbarvideo: {
          Vertical: {
            appHeight: '60%',
            appMarginTop: '0%'
          },
          Horizontal: {
            appHeight: '80%',
            appMarginTop: '0%'
          }
        },
        bannervideo: {
          Vertical: {
            appHeight: '47%',
            appMarginTop: '0%'
          },
          Horizontal: {
            appHeight: '80%',
            appMarginTop: '0%'
          }
        },
        subtoolbarbannervideo: {
          Vertical: {
            appHeight: '51%',
            appMarginTop: '0%'
          },
          Horizontal: {
            appHeight: '80%',
            appMarginTop: '0%'
          }
        }
      }
    }
    const subtoolbarCheck = this['grid' + orientation].subtoolbar.show;
    const bannerWeatherCheck = this['grid' + orientation].banner.show;
    const sliderCheck = this['grid' + orientation].slider.show;
    if(subtoolbarCheck && bannerWeatherCheck && sliderCheck){
        return sizeApplication[this.rest.size].subtoolbarbannervideo[orientation];
    }
    if(subtoolbarCheck && bannerWeatherCheck){
        return sizeApplication[this.rest.size].subtoolbarbanner[orientation];
    }
    if(subtoolbarCheck && sliderCheck){
        return sizeApplication[this.rest.size].subtoolbarvideo[orientation];
    }
    if(bannerWeatherCheck && sliderCheck){
        return sizeApplication[this.rest.size].bannervideo[orientation];
    }
    if(subtoolbarCheck){
        return sizeApplication[this.rest.size].subtoolbar[orientation];
    }
    if(bannerWeatherCheck){
        return sizeApplication[this.rest.size].banner[orientation];
    }
    if(sliderCheck){
        return sizeApplication[this.rest.size].video[orientation];
    }
    return sizeApplication[this.rest.size].normal[orientation];
  }
  appBackgroundStyle(orientation: string){
    if(this['grid' + orientation].configuration.lang_selector === 'flag'){
      return {
        'background': `url(${this['grid' + orientation].configuration.url})`
      };
    }
    return {
      'background': `linear-gradient(${this['grid' + orientation].configuration.color_direction}, ${this['grid' + orientation].configuration.color_start} 0%, ${this['grid' + orientation].configuration.color_end} 100%)`
    };
  }
  appSizeStyle(orientation: string){
    const {appHeight, appMarginTop} = this.appHeightStyle(orientation);
    return {
      'height': appHeight,
      'margin-top': appMarginTop
    };
  }
  weatherNextSevenDays(weatherId: string){
    let weatherDaily = this.rest.response.datas['weather' + weatherId].daily;
    return [weatherDaily[0], weatherDaily[1], weatherDaily[2], weatherDaily[3], weatherDaily[4], weatherDaily[5], weatherDaily[6]];
  }
  weatherThreeHour(weatherId: string){
    const weatherHourly = this.rest.response.datas['weather' + weatherId].hourly;
    return [weatherHourly[0], weatherHourly[3], weatherHourly[6], weatherHourly[9], weatherHourly[12], weatherHourly[15], weatherHourly[18], weatherHourly[21]];
  }
  timeToDayWeekShortening(time: number){
    const dateTime = new Date(time * 1000);

    //Traduccion dias de la semana en banner tiempo
    const dia = this.getDayShort();
    return dia[dateTime.getDay()];

  }
  timeToDayWeek(time: number){
    const dateTime = new Date(time * 1000);
    //Traduccion dias de la semana en banner tiempo
    const dia = this.getDayFull();
    return dia[dateTime.getDay()];

    }
  timeToHourly(time){
    const dateTime = new Date(time * 1000);
    return `${this.formatDateWithCero(dateTime.getHours())}:${this.formatDateWithCero(dateTime.getMinutes())}`;
  }
  dateNumber(time){
    const dateTime = new Date(time * 1000);
    return dateTime.getDate();
  }
  formatDateWithCero(number){
    return number < 10 ? `0${number}` : number;
  }
  clockToolbar = () => {
    const dateToday = new Date();
    this.clockHour = this.formatDateWithCero(dateToday.getHours());
    this.clockMinute = this.formatDateWithCero(dateToday.getMinutes());
    this.clockDay = this.formatDateWithCero(dateToday.getDate());
    this.clockMonth = this.formatDateWithCero(dateToday.getMonth() + 1);
    this.clockYear = dateToday.getFullYear();
    console.log('clockToolbar', 60000 - (dateToday.getSeconds() * 1000));
    this.intervalClock = setTimeout(this.clockToolbar, 60000 - (dateToday.getSeconds() * 1000));
  }
  clickSubToolbar(subtoolbarItem, subtoolbarType, itemModuleDatas){
    console.log("subtoolbarType: ", subtoolbarType);
    console.log("subtoolbarItem: ", subtoolbarItem);
    console.log("itemModuleDatas: ", itemModuleDatas);

    if(itemModuleDatas.type === 'pointsInterest'){
      this.router.navigate(["/list-new"], {queryParams: {pointinterestid: itemModuleDatas.id}});
    }
    if(itemModuleDatas.type === 'pointsInterestID'){
      this.router.navigate(["/product-new"], {queryParams: {pointinterestid_id: itemModuleDatas.id}});
    }

    if(itemModuleDatas.type === 'parentTypePointsInterest'){
      this.router.navigate(["/list-new"], {queryParams: {parentpointinterestid: itemModuleDatas.id}});
    }

    if(itemModuleDatas.type === 'callejero'){
      this.router.navigate(["/map-new"], {queryParams: {streetmap: 'ok'}});
    }

    if(itemModuleDatas.type === 'pdf'){
      if(itemModuleDatas.menu == '1'){
        this.router.navigate(["/list-new"], {queryParams: {pdfId: itemModuleDatas.id}});  
      }
      else{
        this.router.navigate(["/pdf"], {queryParams: {pdfId: itemModuleDatas.id}});
      }
    }

    if(itemModuleDatas.type === 'link'){
      try {  
        const links = JSON.parse(itemModuleDatas.data);
        let link = links[this.rest.codeUsedLang];
        if (link == ''){
          link = links['es'];
        }
        this.router.navigate(["/iframe"], {queryParams: {link: link, id: itemModuleDatas.id}});
      } catch (e) {  
        this.router.navigate(["/iframe"], {queryParams: {link: itemModuleDatas.data, id: itemModuleDatas.id}});
      }

    }

    if(itemModuleDatas.type === 'selfie'){
      if(this.rest.selfie == false){ 
        this.router.navigate(["/iframe"], {queryParams: {id: 'mt'}});
      }
      else{
        this.router.navigate(["/selfie"], {queryParams: {id: 'mock'}});
      }
    }


    //   if(subtoolbarType == 'selfie'){
    //   this.router.navigate(["/selfie"], {queryParams: {subtoolbarItem: subtoolbarItem}});  
    //   }
    // else if(subtoolbarType == 'callejero'){
    //     this.router.navigate(["/map-new"], {queryParams: {streetmap: 'ok'}});
    //     }
    //   else if(subtoolbarType == 'ayu'){
    //     this.router.navigate(["/iframe"], {queryParams: {id: "ada"}});  
    //     }
    //   else if(subtoolbarType == 'ta'){
    //     this.router.navigate(["/iframe"], {queryParams: {id: "ta"}});  
    //   }
    //   else if(subtoolbarType == 'gt'){
    //     this.router.navigate(["/list-new"], {queryParams: {id: "gt"}});  
    //   }
    //   else if(subtoolbarType == 'touristOfficeLoeches'){
    //     this.router.navigate(["/iframe"], {queryParams: {id: "touristOfficeLoeches"}});  
    //   }
    //   else if(subtoolbarType == 'link'){
    //     this.router.navigate(["/iframe"], {queryParams: {id: subtoolbarType, url: subtoolbarSelects}});  
    //   }
    //   else if(subtoolbarType == 'pdfCartaya'){
    //     this.router.navigate(["/pdf"], {queryParams: {id: subtoolbarType, urlPDF: subtoolbarSelects}});  
    //   }
    //   else {
    //   this.router.navigate(["/list-new"], {queryParams: {pointinterestid: subtoolbarSelects}});
    // }
  }
  clickItem(rowNumber, itemKey){
    const itemDemoText = this.gridVertical.items[rowNumber].itemsRow[itemKey].text;
    const itemModuleDatas = this.gridVertical.items[rowNumber].itemsRow[itemKey].module;
    
    if(itemModuleDatas.type === 'void' && this.demo == false){
      this.router.navigate(["/map-new"], {queryParams: {id: 'mock'}});
    }
    if(itemModuleDatas.type === 'void' && this.demo == true){
      console.log('im a demo '+itemDemoText)
      if (itemDemoText == "Ayuntamiento de Almuñecar"){
        this.router.navigate(["/iframe"], {queryParams: {id: 'ada'}});
      }
      if (itemDemoText == "Turismo Almuñecar"){
        this.router.navigate(["/iframe"], {queryParams: {id: 'ta'}});
      }
      if (itemDemoText == "Trámites de la Administración"){
        this.router.navigate(["/list-new"], {queryParams: {id: 'gt'}});
      }
      if (itemDemoText == "Callejero"){
        this.router.navigate(["/list-new"], {queryParams: {pointinterestid: 11}});
      }
    }
    if(itemModuleDatas.type === 'rout'){
    this.router.navigate(["/routes"], {queryParams: {routeid: 1}});
    }
    if(itemModuleDatas.type === 'storeVendor'){
      this.router.navigate(["/product-new"], {queryParams: {storevendorid: itemModuleDatas.id}});
    }
    if(itemModuleDatas.type === 'routes'){
      this.router.navigate(["/list-new"], {queryParams: {routetypeid: itemModuleDatas.id}});
    }
    if(itemModuleDatas.type === 'pointsInterest'){
      this.router.navigate(["/list-new"], {queryParams: {pointinterestid: itemModuleDatas.id}});
    }
    //Añadido enlace a punto de interes directo
    if(itemModuleDatas.type === 'pointsInterestID'){
      this.router.navigate(["/product-new"], {queryParams: {pointinterestid_id: itemModuleDatas.id}});
    }
    //Añadido padre de tipos de puntos de interes
    if(itemModuleDatas.type === 'parentTypePointsInterest'){
      this.router.navigate(["/list-new"], {queryParams: {parentpointinterestid: itemModuleDatas.id}});
    }

    if(itemModuleDatas.type === 'callejero'){
      this.router.navigate(["/map-new"], {queryParams: {streetmap: 'ok'}});
    }
    if(itemModuleDatas.type === 'rss'){
      this.router.navigate(["/rss-new"], {queryParams: {rssId: JSON.stringify(itemModuleDatas.id)}});
    }
    if(itemModuleDatas.type === 'event'){
      this.router.navigate(["/rss-new"], {queryParams: {eventId: JSON.stringify(itemModuleDatas.id)}});
    }
    if(itemModuleDatas.type === 'pdf'){
      if(itemModuleDatas.menu == '1'){
        this.router.navigate(["/list-new"], {queryParams: {pdfId: itemModuleDatas.id}});  
      }
      else{
        this.router.navigate(["/pdf"], {queryParams: {pdfId: itemModuleDatas.id}});
      }
    }

    if(itemModuleDatas.type === 'link'){

      try {  
        const links = JSON.parse(itemModuleDatas.data);
        let link = links[this.rest.codeUsedLang];
        if (link == ''){
          link = links['es'];
        }
        this.router.navigate(["/iframe"], {queryParams: {link: link, id: itemModuleDatas.id}});
      } catch (e) {  
        this.router.navigate(["/iframe"], {queryParams: {link: itemModuleDatas.data, id: itemModuleDatas.id}});
      }

    }
    if(itemModuleDatas.type === 'selfie'){
      if(this.rest.selfie == false){
        
        this.router.navigate(["/iframe"], {queryParams: {id: 'mt'}});
      }
      else{
        this.router.navigate(["/selfie"], {queryParams: {id: 'mock'}});
      }
    }
  }
  public async banana(){
    this.platano++
    console.log(this.bnna);
    if(!this.bnna){
    this.bnna = setTimeout( () => {
      this.platano = 0;
      this.bnna = null;
      console.log('cleared')
 }, 3000);}
    
  }
  public async presentBanana(){
    setTimeout( () => {
      modal.dismiss();
      }, 5000);
    const modal = await this.modalCtrl.create({
      component: BananaPage,
      cssClass: 'banana-modal'
    });
  
    await modal.present();
    const { data } = await modal.onDidDismiss();
    this.platano = 0;
}
  subtoolbarName(subtoolbarItem){
    const actionType = this.gridVertical.subtoolbar[subtoolbarItem].type;
    const actionName =  this.gridVertical.subtoolbar[subtoolbarItem].name;
    if(this.rest.size == 'mobile'){}
    else{
    
    if(actionType === 'beach'){
      console.log('code used lang', this.rest.codeUsedLang);
      console.log('used lang', this.rest.usedLang);
      const beachTranslate = {
        es: 'Playas',
        en: 'Beach',
        fr: 'Playe',
        de: 'Strand'
      };
      return beachTranslate[this.rest.codeUsedLang];
    }
    else if(actionType === 'selfie'){
      console.log('code used lang', this.rest.codeUsedLang);
      console.log('used lang', this.rest.usedLang);
      const selfieTranslate = {
        es: 'Selfie',
        en: 'Selfie',
        fr: 'Selfie',
        de: 'Selfie'
      };
      return selfieTranslate[this.rest.codeUsedLang];
    }
   else if(actionType === 'callejero'){
      console.log('code used lang', this.rest.codeUsedLang);
      console.log('used lang', this.rest.usedLang);
      const selfieTranslate = {
        es: 'Callejero',
        en: 'Street Map',
        fr: 'Plan',
        de: 'Straßenkarte'
      };
      return selfieTranslate[this.rest.codeUsedLang];
    }
   else if(actionType === 'gastronomia'){
      console.log('code used lang', this.rest.codeUsedLang);
      console.log('used lang', this.rest.usedLang);
      const gastroTranslate = {
        es: 'Gastronomia',
        en: 'Gastronomy',
        fr: 'Gastronomie',
        de: 'Gastronomie'
      };
      return gastroTranslate[this.rest.codeUsedLang];
    }
   else if(actionType === 'restaurant'){
      const restaurantTranslate = {
        es: 'Restaurantes',
        en: 'Restaurant',
        fr: 'Restaurant',
        de: 'Restaurant'
      };
      return restaurantTranslate[this.rest.codeUsedLang];
    }
   else if(actionType === 'touristOffice'){
      const touristOfficeTranslate = {
        es: 'Oficinas Turismo',
        en: 'Tourist Office',
        fr: 'Office tourisme',
        de: 'Touristeninformation'
      };
      return touristOfficeTranslate[this.rest.codeUsedLang];
    }
  else if(actionType === 'museum'){
      const museumTranslate = {
        es: 'Museos',
        en: 'Museum',
        fr: 'Musée',
        de: 'Museum'
      };
      return museumTranslate[this.rest.codeUsedLang];
    }
   else if(actionType === 'monument'){
      const monumentTranslate = {
        es: 'Monumentos',
        en: 'Monument',
        fr: 'Monument',
        de: 'Denkmal'
      };
      return monumentTranslate[this.rest.codeUsedLang];
    }
  else if(actionType === 'touristActive'){
      const touristActiveTranslate = {
        es: 'Actividades',
        en: 'Activities',
        fr: 'Activités',
        de: 'Beschäftigung'
      };
      return touristActiveTranslate[this.rest.codeUsedLang];
    }
  else if(actionType === 'pointsInterest'){
      const pointsInterestTranslate = {
        es: 'Puntos Interés',
        en: 'Point Interest',
        fr: "Point d'intérêt",
        de: 'Sehenswürdigkeiten'
      };
      return pointsInterestTranslate[this.rest.codeUsedLang];
    }
  else if(actionType === 'party'){
      const partyTranslate = {
        es: 'Fiestas',
        en: 'Party',
        fr: "Fête",
        de: 'Fest'
      };
      return partyTranslate[this.rest.codeUsedLang];
    }
    else{
      return actionName;
    }
  }
  }
  backgroundTextOption(itemType){
    if(itemType !== "event"){
      return "linear-gradient(180deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.4) 60%, rgba(0, 0, 0, 0) 100%)";
    }
    return "";
  }
  goHome(){
    if(this.platano == 9){
      this.presentBanana();
    }
    this.router.navigateByUrl('/home-new');
  }
  clickBannerMonitor(event){
    if(this.GUI.bannerMonitor.classList.contains('banner-monitor-maximize')){
      this.GUI.bannerMonitor.classList.replace('banner-monitor-maximize', 'banner-monitor-minimize');
      this.bannerBackgroundColor = this.gridVertical.banner.style.backgroundColor;
      /* this.GUI.bannerMonitor.classList.add('banner-monitor');
      this.GUI.bannerMonitor.classList.remove('banner-monitor-maximize'); */
    }else{
      this.GUI.bannerMonitor.classList.replace('banner-monitor-minimize', 'banner-monitor-maximize');
      this.bannerBackgroundColor = this.gridVertical.banner.style.backgroundColorExpand;
      //Cambio de color coroportavo en baner tiempo LOECHES
      if (this.rest.mac === "loeches"){
        this.bannerBackgroundColor = this.color_corp;
      }
      /* this.GUI.bannerMonitor.classList.add('banner-monitor-maximize');
      this.GUI.bannerMonitor.classList.remove('banner-monitor'); */
    }
  }


  getDayShort(){
    const dayShort = {
      es: ['Dom','Lun','Mar','Mie','Jue','Vie','Sab'],
      en: ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'],
      fr: ['Dim','Lun','Mar','Mer','Jeu','Ven','Sam'],
      de: ['So','Mo','Di','Mi','Do','Fr','Sa']
    };
    return dayShort[this.rest.codeUsedLang] ? dayShort[this.rest.codeUsedLang] : dayShort['es'];
  }

  getDayFull(){
    const dayFull = {
      es: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
      en: ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'],
      fr: ['Dimanche','Lundi','Mardi','Mercredi','Jeudi','Vendredi','Samedi'],
      de: ['So','Mo','Di','Mi','Do','Fr','Sa']
    };
    return dayFull[this.rest.codeUsedLang] ? dayFull[this.rest.codeUsedLang] : dayFull['es'];
  }

  //Presentar QR de abajo
  async presentAlert() {
    this.makeQRCode();
    const textQR = "Llévatelo a tu móvil";
    const QRTranslated = (this.translateService.translations[this.translateService.currentLang])[textQR];
    const alert = await this.alertController.create({
      cssClass: 'custom-alert-danger',
      header: QRTranslated,
      message: `<img src="${this.qr}" style="border-radius: 2px">`,
      //buttons: ['OK']
    });

    await alert.present();

    setTimeout(() => {
      alert.dismiss();
    }, 30000);

    const { role } = await alert.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }

  //QR para poder acceder a la web
  makeQRCode() {
    const qrcode = QRCode;
    const self = this;
    qrcode.toDataURL(`https://app.iurban.es/?mac=${this.rest.mac}&newcms=true&size=monitor`, { errorCorrectionLevel: 'H' }, function (err, url) {
      self.qr = url;
    });
    console.log("QR: ", this.rest.mac)
  }
}
