import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'license-manager', pathMatch: 'full' },
  { path: 'product-details-page', loadChildren: './product-details-page/product-details-page.module#ProductDetailsPagePageModule' },
  { path: 'cart-page', loadChildren: './cart-page/cart-page.module#CartPagePageModule' },
  { path: 'license-manager', loadChildren: './license-manager/license-manager.module#LicenseManagerPageModule' },
  { path: 'link', loadChildren: './link/link.module#LinkPageModule' },
  { path: 'survey', loadChildren: './survey/survey.module#SurveyPageModule' },
  { path: 'networks', loadChildren: './networks/networks.module#NetworksPageModule' },
  { path: 'standby', loadChildren: './standby/standby.module#StandbyPageModule' },
  { path: 'modal', loadChildren: './modal/modal.module#ModalPageModule' },
  { path: 'sleep', loadChildren: './sleep/sleep.module#SleepPageModule' },
  { path: 'home', loadChildren: './home/home.module#HomePageModule' },
  { path: 'pdf', loadChildren: './pdf/pdf.module#PdfPageModule' },
  { path: 'selfie', loadChildren: './selfie/selfie.module#SelfiePageModule' },
  { path: 'iframe', loadChildren: './iframe/iframe.module#iframePageModule' },
  { path: 'maintenance', loadChildren: './maintenance/maintenance.module#MaintenancePageModule' },
  { path: 'interactive-standby', loadChildren: './interactive-standby/interactive-standby.module#InteractiveStandbyPageModule' },
  { path: 'home-new', loadChildren: './home-new/home-new.module#HomeNewPageModule' },
  { path: 'list-new', loadChildren: './list-new/list-new.module#ListNewPageModule' },
  { path: 'product-new', loadChildren: './product-new/product-new.module#ProductNewPageModule' },
  { path: 'map-new', loadChildren: './map-new/map-new.module#MapNewPageModule' },
  { path: 'rss-new', loadChildren: './rss-new/rss-new.module#RssNewPageModule' },
  { path: 'standby-new', loadChildren: './standby-new/standby-new.module#StandbyNewPageModule' },
  { path: 'map-open-street', loadChildren: './map-open-street/map-open-street.module#MapOpenStreetPageModule' },  { path: 'routes', loadChildren: './routes/routes.module#RoutesPageModule' },







];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
