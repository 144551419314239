export const TAG = 'HOTEL_DIGITAL';

export const CIVICITI = '45';
export const CIVICITI_DEMOS = '66';

export const MAC_DEBUG = 'C0:84:7D:B7:1B:D6';
export const DEBUG = false;
export const PREPRODUCCION_MODE = false;
export const VISIBLE_PARAMS = true;
export const HIDE_FIRST_ITEM = false;
export const DEMO_BANNER = false;

// METHODS CALL
export const CALL_PHONE = 1;
export const CALL_IP_NODE_JS = 2;
export const CALL_IP_CENTRALLY = 3;

// CALL STATES
export const STATE_CALLING = 'STATE_CALLING';
export const STATE_ONLINE = 'STATE_ONLINE';
export const STATE_HUNG = 'STATE_HUNG';

export const METHOD_CALL = 'CALL_IP';

// SCHEDULE TYPES
export const SCHEDULE_24_HOURS = 'Guardia 24 horas';
export const SCHEDULE_22_HOURS = 'Refuerzo hasta 22 horas';

// CONFIG APP
export const STAND_BY_ACTIVE = true;
export const SHOW_TITLES = true;
export const TIME_CHECK_DATA = 5000;
export const UPDATE_ONLY_SHOWING_STANDBY = true;
export const SHOW_PRODUCTS_IN_BANNER = true;
export const SHOW_BUTTON_FOOD = false;
export const SHOW_BOOKING = true;
export const SHOW_SUBBUTTONS_IN_TOOLBAR = true;

// REQUEST
export const REQUEST_SELECTIVE = 1;
export const REQUEST_ALL = 2;
export const REQUEST_NONE = 3;

// MENU APP SECTION
export const SECTION_MENU_GRID = 4;

// VISIBILITY CONSTANTS
export const ITEM_IS_ACTIVE = 1;

 // APP TYPES
export const TYPE_RESTAURANT = 1;
export const TYPE_HOTEL = 2;
export const TYPE_ESTABLISHMENT = 3;

// PRODUCT INTERFACE CONSTANTS
export const INTERFACE_PRODUCT_LIST_NO_PHOTO = 7;
export const INTERFACE_PRODUCT_LIST_PHOTO = 1;
export const INTERFACE_PRODUCT_PHOTO_AND_DESCRIPTION = 2;
export const INTERFACE_PRODUCT_PHOTO_NO_DESCRIPTION = 3;
export const INTERFACE_PRODUCT_TYPE_EVENT = 4;
export const INTERFACE_PRODUCT_NO_PHOTO = 5;
export const INTERFACE_PRODUCT_HEADER_AND_DESCRIPTION = 0;

// ITEM TYPE CONSTANTS
export const ITEM_TYPE_SUBMENU = 1;
export const ITEM_TYPE_LINK = 2;
export const ITEM_TYPE_PRODUCT = 3;

// LINK TYPE CONSTANTS
export const TYPE_LINK_URL_EXTERNAL = 1;
export const TYPE_LINK_APP = 2;
export const TYPE_LINK_SURVEY = 3;
export const TYPE_LINK_URL_INTERNAL = 4;
export const TYPE_LINK_OPTION = 5;

// SURVEY QUESTIONS TYPE
export const TYPE_QUESTION_3_FACES = '1';
export const TYPE_QUESTION_4_FACES = '2';
export const TYPE_QUESTION_5_FACES = '3';
export const TYPE_QUESTION_UNIQUE_ANSWER = '4';
export const TYPE_QUESTION_MULTIPLE_ANSWER = '5';
export const TYPE_QUESTION_TEXT_SQUARE = '6';
export const TYPE_QUESTION_FREE_ANSWER = '7';

// OPTIONS TYPE
export const OPTION_PAY = '1';
export const OPTION_CALL_WAITER = '2';

// TYPES REFERENCE
export const TYPE_ALL = 'ALL';
export const TYPE_PILLOWS = 'ALH';
export const TYPE_SERVICES = 'BAR';
export const TYPE_EXPERIENCES = 'EXP';

// ACTIONS STATS
export const ACTION_TOUCH = 1;
export const ACTION_ORDER = 2;
export const ACTION_ADD_PRODUCT = 3;
export const ACTION_CALL_WAITER = 4;
export const ACTION_BILL_CARD = 5;
export const ACTION_BILL_CASH = 6;
export const ACTION_PRINT_ORDER = -1;
export const ACTION_ENTER_APP = 8;

// VARS STATS
export const MAC = "mac";
export const STABLISHMENT = "id_establishment";
export const MENU_APP = "id_menu_app";
export const LANG = "id_language";
export const ACTION = "id_stat_action";
export const MENU_LEVEL = "id_menu_level";
export const PRODUCT_ID_RELATIVE = "id_relative";
export const DATE_CREATION = "created_at";
export const TOTAL = "amount";

// DESTINY ORDERS
export const DESTINY_PRINTER = 1;
export const DESTINY_ORDER_PANEL = 2;

// TYPE NODE DEVICE
export const SENDER = 0;
export const RECEIVER = 1;

// URLS
// export const URL_BASE = document.URL.startsWith('http://localhost')? 'http://127.0.0.1:8000/' : document.URL.startsWith('https://cmspreproduccion') || PREPRODUCCION_MODE ? "https://apipreproduccion.iurban.es/" : "https://api.iurban.es/";

//export const URL_BASE = PREPRODUCCION_MODE ? 'http://api.cms-iurban.com/' : 'https://api.iurban.es/'; //"https://apipreproduccion.iurban.es/" : "https://api.iurban.es/";
//export const URL_LICENSE =  PREPRODUCCION_MODE ? 'http://api.cms-iurban.com/android/apk' : 'https://api.iurban.es/android/apk'; 

export const URL_BASE = PREPRODUCCION_MODE ? "https://apipreproduccion.iurban.es/" : "https://api.iurban.es/";
export const URL_NEW = "https://cms-iurban.com/";


export const apiUrl = "https://cms-iurban.com/";
// export const apiUrl = "http://192.168.1.117:8888/";


export const URL_LICENSE =  PREPRODUCCION_MODE ? 'https://apipreproduccion.iurban.es/android/apk' : 'https://api.iurban.es/android/apk';
export const URL_IURBAN_APPS = 'https://api.iurban.es/android/aplicaciones';
export const URL_IURBAN = URL_BASE + 'android/aplicacion/';
//export const URL_IURBAN_NEW_CMS = 'http://localhost/json';
//export const URL_IURBAN_NEW_CMS = 'https://preproduccion.cms-iurban.com/json';

export const URL_IURBAN_NEW_CMS = 'https://cms-iurban.com/json';
// export const URL_IURBAN_NEW_CMS = 'http://192.168.1.117:8888/json';


export const STATS_SURVEY_URL = URL_BASE + 'cms/encuesta-intento';

// ORDERS
export const CREATE_ORDER = PREPRODUCCION_MODE ? 'https://apipreproduccion.iurban.es/api/cartOrder' : 'https://api.iurban.es/api/cartOrder';
export const READ_NEW_ORDERS = 'https://api.iurban.es/api/cartOrder/getNewOrders/';
export const READ_MESSAGES = 'https://api.iurban.es/api/cartOrder/getOrderUpdate/';

// MESSAGES
export const READ_MESSAGE_BY_MENUAPP_CALL_WAITER = PREPRODUCCION_MODE ? 'https://apipreproduccion.iurban.es/api/messageByMenuAppCallWaiter/' :
'https://api.iurban.es/api/messageByMenuAppCallWaiter/';
export const READ_MESSAGE_BY_MENUAPP_ORDER = PREPRODUCCION_MODE ? 'https://apipreproduccion.iurban.es/api/messageByMenuAppOrder/' :
'https://api.iurban.es/api/messageByMenuAppOrder/';

export const SEND_MESSAGE = PREPRODUCCION_MODE ? 'https://apipreproduccion.iurban.es/api/message' : 'https://api.iurban.es/api/message';

// STATS
export const STATSURL = URL_BASE + 'android/estadistica';
export const STATS_DEVICE = URL_BASE + 'android/conexion';
export const URL_UPDATE_LOCATION = URL_BASE + 'api/kiosk/deviceLocation';

// EMAIL
export const URL_SURVEY_EMAIL =  URL_BASE + 'api/sendemail';
export const URL_SEND_EMAIL =  URL_BASE + 'api/send_email';

// URLS DST
export let category: any;
export let deviceId = 'DESKTOP-BVQGCVD:4TWR-LAMH-QUBF-VBFX';
// export let number : any;
const URL_BASE_DST = 'http://192.168.1.114:8081/dstwcf';

// URLS LOCALHOST
export const URL_LOCALHOST = 'http://192.168.1.134/api/dst/jsonapp';
export const URL_LOCALHOST_ORDER = 'http://192.168.1.134/api/dst/jsonapp';
// export let number : any;

// API IMPALA
export const URL_IMPALA_GET_BILL = 'https://api.getimpala.com/v2/hotel/3d36828b-f462-48ca-981a-8514efa7d7f7/bookings/5dde5e2baea3b4238c4d9ff7/bills';



// DATA_FROM
export const IURBAN = 'iurban';
export const LOCALHOST = 'localhost';

// API
export const API = IURBAN;


export const openWeatherToFontAwesomeWeatherIcons = {

    '01d': 'wi-day-sunny',

    '01n': 'wi-night-clear',

    '02d': 'wi-day-cloudy',

    '02n': 'wi-night-cloudy',

    '03d': 'wi-cloudy',

    '03n': 'wi-cloudy',

    '04d': 'wi-cloudy',

    '04n': 'wi-cloudy',

    '09d': 'wi-showers',

    '09n': 'wi-showers',

    '10d': 'wi-day-rain',

    '10n': 'wi-night-rain',

    '11d': 'wi-day-thunderstorm',

    '11n': 'wi-night-thunderstorm',

    '13d': 'wi-day-snow',

    '13n': 'wi-night-snow',

    '50d': 'wi-day-fog',

    '50n': 'wi-night-fog'

};
